<template>
  <navigation-tabs>
  <v-container class="d-flex fill-height align-start mt-4 px-8">
    <show-message/>

    <v-row class="py-4 px-4">
      <v-col cols=12 class="px-0">
        <p class="text-h5 el_centered_h5">
          <span class="pr-1" style="font-size: 1.25em;">{{ totalCheckedCnt }}</span>ファイルのチェックが完了しました。
        </p>
      </v-col>
      <v-col cols=12 md=8 class="pa-0">
        <p class="pa-0 ma-0">
          エラーファイルがある場合は、エラーファイル一覧をご確認ください。<br />
          エラーファイル数が0の場合は、ZIPファイルをダウンロードできます。
        </p>
      </v-col>
      <v-col cols=12 md=4 class="d-flex align-end justify-end ma-0 pa-0">
        <v-btn
          to="/file-upload"
          width="250"
          height="36"
          color="#edebf47d"
        >
        <span class="text-subtitle-2 font-weight-bold primary--text" 
              style="display:inline-flex; align-items:center; column-gap:8px;">
          <v-icon
              small
              color="primary"
            >
              mdi-plus-circle
            </v-icon>
          PDFファイル追加</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-0 mb-6 d-flex align-center justify-center" style="row-gap:16px;">
      <v-col cols="12" align="center">
        <v-card
          outlined
        >
          <v-card-text
            class="text-body-2 mt-8 mb-4 mx-0"
          >
            <v-row>
              <v-col cols="12" sm="7">
                <v-row justify="space-between" style="row-gap:8px;">
                  <v-col cols="12" sm="7">
                    <v-row justify="center" style="min-height:40px;">エネルギー消費性能計算結果<br />ファイル数</v-row>
                    <v-row
                      class="pt-2"
                      justify="center"
                      align="center"
                    >
                      <span class="text-h5 text-md-h4 pr-2">{{ correctCnt }}</span>
                      ファイル
                    </v-row>
                  </v-col>
                  <v-divider class="hidden-sm-and-up"></v-divider>
                  <v-divider vertical class="d-none d-sm-flex"></v-divider>
                  <v-col cols="12" sm="5">
                      <v-row justify="center" style="min-height:40px;">住⼾数</v-row>
                      <v-row
                        class="pt-2"
                        justify="center"
                        align="center"
                      >
                        <span class="text-h5 text-md-h4 pr-2">{{ householdCnt }}</span> ⼾
                      </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                    to="/file-list"
                    width="80%"
                    height="48"
                    color="cl_success_light"
                    class="el_btnErrorList"
                    :disabled="correctCnt == 0">
                      <span class="white--text text-darken-1 font-weight-bold">チェック通過ファイル一覧</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" sm="5">
                <v-row justify="center" style="min-height:40px;">エラーファイル数</v-row>
                <v-row
                  class="pt-2"
                  justify="center"
                  align="center"
                >
                  <span class="text-h5 text-md-h4 pr-2">{{ errorCnt }}</span> ファイル
                </v-row>
                <v-row
                  class="pt-5"
                  justify="center"
                >
                  <v-btn
                    to="/error-result"
                    width="80%"
                    height="48"
                    color="cl_error_lignt"
                    class="el_btnErrorList"
                    :disabled="errorCnt == 0"
                  >
                    <span class="white--text text-darken-1 font-weight-bold">エラーファイル一覧</span>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
          @click="download"
          height="75"
          color="#5E539A"
          :disabled="correctCnt == 0 || errorCnt > 0"
        >
          <span class="text-h5 white--text">ZIPファイルダウンロード</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mx-0 mt-10 pa-0 bl_fixedFooter">
      <v-btn
       @click="showBackDialog = true"
       plain
       height="60"
       class="font-weight-bold text-h6 pl-0">
        <v-icon large>mdi-chevron-left</v-icon>
        戻る
      </v-btn>
    </v-row>

    <!-- ファイルアップロード済み場合の戻る処理を確認するダイアログボックス -->
    <show-dialog
      v-if="showBackDialog"
      :initialShowDialog=showBackDialog
      dialogMsg="アップロードされたファイル情報が全て失われます。<br>よろしいですか。"
      @confirmDialog=confirmBackDialog
      @closeDialog="showBackDialog = false"
    ></show-dialog>

    <div
      class="progress"
      v-show="progress"
    >
      <v-progress-circular
          indeterminate
          :size="150"
          :width="15"
          color="grey"
          class="circular"
      ></v-progress-circular>
    </div>
  </v-container>
  </navigation-tabs>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { getTimestamp } from '@/util'
import NavigationTabs from '@/components/templates/NavigationTabs.vue'
import ShowMessage from '@/components/ShowMessage.vue'
import ShowDialog from '@/components/ShowDialog.vue'

export default {
  components: { 
    NavigationTabs,
    ShowMessage,
    ShowDialog
  },
  data() {
    return {
      // 戻る処理を確認するダイアログボックス表示の有無
      showBackDialog: false,
      // ZIPファイルダウンロード進行中表示の有無
      progress: false
    }
  },

  computed: {
    ...mapGetters([
      'isError',
      'selectedHouseholdType',
      'selectedReportType',
      'checkedResult',
      'totalCheckedCnt',
      'correctCnt',
      'householdCnt',
      'errorCnt'
    ])
  },

  methods: {
    ...mapActions(['downloadZip']),

    async download() {
      this.progress = true

      const content = await this.downloadZip()

      if (!this.isError) {
        // Blob作成
        const blob = new Blob([content])
        // BlobからオブジェクトURLを作成する
        const url = URL.createObjectURL(blob, {type: "application/zip"})
        // ダウンロード用にリンクを作成する
        const link = document.createElement("a")
        // リンク先に上記で生成したURLを指定する
        link.href = url
        // download属性にファイル名を指定する
        const timestamp = getTimestamp()
        link.setAttribute('download', 'TRファイルチェック_' + this.selectedHouseholdType + '_' + this.selectedReportType + '_' + timestamp + '.zip');
        // 作成したリンクをクリックしてダウンロードを実行する 
        link.click()
        // createObjectURLで作成したオブジェクトURLを解放する
        URL.revokeObjectURL(url)
      }
      this.progress = false
    },

    // 戻る処理を確認した後ホーム画面へ遷移
    confirmBackDialog() {
      this.$router.push('/')
    }
  }
}
</script>

<style>
.el_btnErrorList.v-btn--disabled{
  opacity: .5;
}
</style>